import { NgModule } from "@angular/core";
import { Routes, RouterModule, ExtraOptions, PreloadAllModules } from "@angular/router";
import { PagesComponent } from "./pages/pages.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";

export const routes: Routes = [
  {
    path: "",
    component: PagesComponent,
    children: [
      // Default Routes
      { path: "", redirectTo: "home", pathMatch: "full" },
      {
        path: "",
        loadChildren: () => import("./pages/home/home.module")
          .then((m) => m.HomeModule)
      },
      // Authentication Routes
      {
        path: "login",
        loadChildren: () => import("./pages/auth/login/login.module")
          .then((m) => m.LoginModule)
      },
      {
        path: "register",
        loadChildren: () => import("./pages/auth/register/register.module")
          .then((m) => m.RegisterModule)
      },
      {
        path: "forgot",
        loadChildren: () => import("./pages/auth/forgot/forgot.module")
          .then((m) => m.ForgotModule)
      },
      {
        path: "auth/changePassword",
        loadChildren: () => import("./pages/auth/changePassword/changePassword.module")
          .then((m) => m.ChangePasswordModule)
      },
      /** Logout is handled by a function call on specific buttons or within the timeout service.
       * Reroutes to home -dkg */
      // Authorized Routes
      {
        path: "dashboard",
        loadChildren: () => import("./pages/account/dashboard/dashboard.module")
          .then((m) => m.DashboardModule)
      },
      {
        path: "properties",
        loadChildren: () => import("./pages/properties/properties.module")
          .then((m) => m.PropertiesModule)
      },
      {
        path: "properties/:id",
        loadChildren: () => import("./pages/properties/propertyDetail/propertyDetail.module")
          .then((m) => m.PropertyDetailModule)
      },
      {
        path: "propertyOnboarding",
        loadChildren: () => import("./pages/property-onboarding/property-onboarding.module")
          .then((m) => m.PropertyOnboardingModule)
      },
      {
        path: "calendars",
        loadChildren: () => import("./pages/calendar/calendar.module")
          .then((m) => m.CalendarModule)
      },
      {
        path: "profile",
        loadChildren: () => import("./pages/account/profile/profile.module")
          .then((m) => m.ProfileModule)
      },
      {
        path: "payout",
        loadChildren: () => import("./pages/payout/payout.module")
          .then((m) => m.PayoutModule) },
      {
        path: "inspection",
        loadChildren: () => import("./pages/inspection/inspection.module")
          .then((m) => m.InspectionModule)
      },
      {
        path: "bookings",
        loadChildren: () => import("./pages/bookings/bookings.module")
          .then((m) => m.BookingsModule)
      },
      {
        path: "bookings/:bookingId",
        loadChildren: () => import("./pages/bookings/bookingDetail/bookingDetail.module")
          .then((m) => m.BookingDetailModule)
      },
      // Admin Routes
      {
        path: "admin/users",
        loadChildren: () => import("./pages/users/users.module")
          .then((m) => m.UsersModule)
      }
    ],
  },
  // Top Level 404 Page - Keep This Absolute Last In Routes List
  { path: "**", component: NotFoundComponent }
];

const routerOptions: ExtraOptions = {
  preloadingStrategy: PreloadAllModules, // <- comment this line to activate lazy load
  // relativeLinkResolution: "corrected",
  initialNavigation: "enabledBlocking", // for one load page, without reload
  // useHash: true
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
