import { Directive, ElementRef, HostListener, Renderer2 } from "@angular/core";

@Directive({
  selector: "input[maskedPhoneNumber]",
})
export class MaskedPhoneNumberDirective {
  constructor(private el: ElementRef) {}

  @HostListener("keypress", ["$event"])
  onInputChange(event: any) {
    const input = event.target;
    const value = input.value.replace(/\D/g, ""); // Remove non-numeric characters

    if (value.length <= 3) {
      input.value = value;
    } else if (value.length <= 6) {
      input.value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
    } else {
      input.value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }
  }
}
