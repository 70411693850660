export interface NavigationModel {
  pushingTo: string;
  pushedFrom: string;
  pageRestrictedToUser: boolean;
  loggedInName: string;
  role: string;
  sitePages: {
    dashboardIsActive: boolean;
    calendarIsActive: boolean;
    propertyIsActive: boolean;
    bookingsAreActive: boolean;
    inspectionsIsActive: boolean;
    paymentsIsActive: boolean;
    profileIsActive: boolean;
    userPageIsActive: boolean;
  }
}

export const initializeNavigationModelData = {
  pushingTo: "",
  pushedFrom: "AppComponent",
  pageRestrictedToUser: false,
  loggedInName: "Host Name",
  role: "host",
  sitePages: {
    dashboardIsActive: false,
    calendarIsActive: false,
    propertyIsActive: false,
    bookingsAreActive: false,
    inspectionsIsActive: false,
    paymentsIsActive: false,
    profileIsActive: false,
    userPageIsActive: false,
  }
}
