import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { RegistrantModel, UserProfileModel, CompanyProfileModel } from "../shared/models/user.model";
import { PropertyOnboarding, CommissionTypes } from "../shared/models/property.model";
import { AuthModel } from "../shared/models/auth.model";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  private loggedInUserSubject = new BehaviorSubject<AuthModel | null>(null);
  loggedInUserSubject$ = this.loggedInUserSubject.asObservable();

  private registrantSubject = new BehaviorSubject<RegistrantModel | null>(null);
  registrantData$ = this.registrantSubject.asObservable();

  private userProfileSubject = new BehaviorSubject<UserProfileModel | null>(null);
  userProfileData$ = this.userProfileSubject.asObservable();

  private femaIdSubject = new BehaviorSubject<string | null>(null);
  femaIdData$ = this.femaIdSubject.asObservable();

  private companyProfileSubject = new BehaviorSubject<CompanyProfileModel | null>(null);
  companyProfileData$ = this.companyProfileSubject.asObservable();

  private inspectionPackageSubject = new BehaviorSubject<CommissionTypes[]>([]);
  inspectionPackageData$ = this.inspectionPackageSubject.asObservable();

  private propertyOnboardingIdSubject = new BehaviorSubject<number | null>(null);
  propertyOnboardingId$ = this.propertyOnboardingIdSubject.asObservable();

  private propertyOnboardingSubject = new BehaviorSubject<PropertyOnboarding | null>(null);
  propertyOnboarding$ = this.propertyOnboardingSubject.asObservable();

  /**
   * Uses a 'hybrid' approach in case the data is missing from the BehaviorSubject
   */
  private retrieveData<T>(localStorageKey: string, subject: BehaviorSubject<T>): T | null {
    // Try to get data from the BehaviorSubject
    const subjectValue = subject.value;
    if (subjectValue) {
      localStorage.setItem(localStorageKey, JSON.stringify(subjectValue));
      return subjectValue;
    }

    // If data is not in the BehaviorSubject, try to get it from localStorage
    const data = localStorage.getItem(localStorageKey);
    return data ? JSON.parse(data) : null;
  }

  constructor(private router: Router) {}

  public sanitizeData(data: any): any {
    const sanitizedData: any = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        if (typeof value === "string") {
          // Remove escape characters (\) from the string value
          sanitizedData[key] = value.replace(/\\/g, "");
        } else {
          sanitizedData[key] = value;
        }
      }
    }
    return sanitizedData;
  }

  /**
   ** User Log-in / Registrant
   */
  public loadRegistrantData() {
    const registrantDataJson = localStorage.getItem("registrant");
    if (registrantDataJson) {
      const registrantData: RegistrantModel = JSON.parse(registrantDataJson);
      this.registrantSubject.next(registrantData);
    }
  }

  public addSession(loggedInUser: AuthModel) {
    this.loggedInUserSubject.next(loggedInUser);
    localStorage.setItem("loggedInUser", JSON.stringify(loggedInUser));
  }

  public getSession() {
    return localStorage.getItem("loggedInUser");
  }

  public addLogin(registrantData: RegistrantModel) {
    this.registrantSubject.next(registrantData);
    localStorage.setItem("registrant", JSON.stringify(registrantData));
  }

  public removeLogin(redirectRoute: string = "/login") {
    // Clear all user-related data
    this.registrantSubject.next(null);
    this.userProfileSubject.next(null);
    this.propertyOnboardingIdSubject.next(null);

    // Clear local storage items
    localStorage.removeItem("registrant");
    localStorage.removeItem("accountProfile");
    localStorage.removeItem("propertyOnboardingId");

    this.router.navigate([redirectRoute]);
  }

  public isUserLoggedIn(): boolean {
    const registrantStringData = localStorage.getItem("registrant");
    return !!registrantStringData;
  }

  public getRegistrantData(): RegistrantModel | null {
    return this.retrieveData("registrant", this.registrantSubject);
  }

  public setFemaId(femaId: string) {
    this.femaIdSubject.next(femaId);
    localStorage.setItem("accountProfile", femaId.toString());
  }

  /**
   ** User Account Profile
   */
  public setUserProfileData(userProfileData: UserProfileModel) {
    this.userProfileSubject.next(userProfileData);
    localStorage.setItem("accountProfile", JSON.stringify(userProfileData));
  }

  public clearUserProfileData() {
    this.userProfileSubject.next(null);
    localStorage.removeItem("accountProfile");
  }

  public getUserProfileData(): any {
    return this.retrieveData("accountProfile", this.userProfileSubject);
  }

  /**
   ** User Company Profile
   */
  public setCompanyProfileData(companyProfileData: CompanyProfileModel) {
    this.companyProfileSubject.next(companyProfileData);
    localStorage.setItem("accountProfile", JSON.stringify(companyProfileData));
  }

  public clearCompanyProfileData() {
    this.companyProfileSubject.next(null);
    localStorage.removeItem("accountProfile");
  }

  public getCompanyProfileData(): CompanyProfileModel | null {
    return this.retrieveData("accountProfile", this.companyProfileSubject);
  }

  /**
   * Inpsection Packages
   */
  public setInspectionPackages(commissionTypes: CommissionTypes[]) {
    this.inspectionPackageSubject.next(commissionTypes);
    localStorage.setItem("inspectionPackages", JSON.stringify(commissionTypes));
  }

  //? Not sure if this is even needed
  public clearInspectionPackages() {
    this.inspectionPackageSubject.next([]);
    localStorage.removeItem("inspectionPackages");
  }

  public getInspectionPackages(): CommissionTypes[] {
    // Try to get data from the BehaviorSubject
    const subjectValue = this.inspectionPackageSubject.value;
    if (subjectValue) {
      localStorage.setItem("inspectionPackages", JSON.stringify(subjectValue));
      return subjectValue;
    }

    // If data is not in the BehaviorSubject, try to get it from localStorage
    const data = localStorage.getItem("inspectionPackages");
    return data ? JSON.parse(data) : [];
  }

  /**
   ** Property Onboarding
   */
  public setPropertyOnboardingId(propertyId: number) {
    this.propertyOnboardingIdSubject.next(propertyId);
    localStorage.setItem("propertyOnboardingId", propertyId.toString());
  }

  public setPropertyOnboarding(propertyData: PropertyOnboarding): void {
    this.propertyOnboardingSubject.next(propertyData);
  }

  public clearPropertyOnboardingId(): void {
    this.propertyOnboardingIdSubject.next(null);
    localStorage.removeItem("propertyOnboardingId");
  }

  public clearPropertyOnboarding(): void {
    this.propertyOnboardingSubject.next(null);
  }

  public getPropertyOnboardingId(): number | null {
    return this.retrieveData("propertyOnboardingId", this.propertyOnboardingIdSubject);
  }

  //? Should I also store the entire property onboarding record? Is it bad to make multiple requests..
  //? ..to get the record each time the user goes through, or completes, an onboarding step/wizard screen?
  public clearAll(): void {
    localStorage.removeItem("registrant");
    localStorage.removeItem("accountProfile");
    localStorage.removeItem("propertyOnboardingId");

    this.clearUserProfileData();
    this.clearPropertyOnboardingId();
    this.clearCompanyProfileData();
  }

  public getPropertyOnboardingData(): PropertyOnboarding | null {
    return this.propertyOnboardingSubject.value;
  }
}
