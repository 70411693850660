import { Component, OnInit, ViewChild, HostListener, Inject, PLATFORM_ID } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Settings, AppSettings } from "../app.settings";
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
})

// TODO: adjust as needed
export class PagesComponent implements OnInit {
  @ViewChild("sidenav") sidenav: any;
  // public toolbarTypes = [1, 2];
  // public headerTypes = ["default", "image", "carousel", "map", "video"];

  /*
  Server-Side Rendering (SSR): When your Angular application is being rendered on the server, the PLATFORM_ID will have a value of server.
  This is because there is no browser environment on the server, and certain operations like accessing the DOM or window object are not possible.

  Client-Side Rendering (CSR): When your Angular application is running in the browser, the PLATFORM_ID will have a value of browser.
  This indicates that you are in a browser environment where DOM operations and access to the window object are available.
  */

  public settings: Settings;
  constructor(public appSettings: AppSettings, public router: Router, @Inject(PLATFORM_ID) private platformId: Object) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    // this.toolbarTypeOption = this.settings.toolbar;
    // this.headerTypeOption = this.settings.header;
    // this.searchPanelVariantOption = this.settings.searchPanelVariant;

    if (isPlatformBrowser(this.platformId)) {
      // Check if the application is running in the browser
      window.scrollTo(0, 0); // Scroll to the top of the page
    }
  }
}
