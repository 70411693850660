<div class="confirmation_dialog">
  <h2 mat-dialog-title>Confirmation</h2>
  <mat-dialog-content>
    {{data.message}}
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial>No</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
  </mat-dialog-actions>
</div>
