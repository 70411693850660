<div class="app-complex-modal">
  <h2 [style.color]="data.titleColor || 'black'" [style.text-shadow]="data.titleColor">{{ data.title }}</h2>
  <h3>{{ data.subtitle }}</h3>

  <div class="modal-content">
    <ul *ngIf="data.bulletList1 && data.bulletList1.length > 0" class="modal-list">
      <h4 *ngIf="data.secondarySubtitle1">{{ data.secondarySubtitle1 }}</h4>
      <h5 *ngIf="data.tertiarySubtitle1">{{ data.tertiarySubtitle1 }}</h5>

      <li *ngFor="let item of data.bulletList1" [ngClass]="{ numbered: item.type === 'numbered', bulleted: item.type === 'bullet' }">
        <b>{{ item.title }}</b> <span [innerHTML]="item.body"></span>
      </li>
    </ul>

    <ul *ngIf="data.bulletList2 && data.bulletList2.length > 0" class="modal-list">
      <h4 *ngIf="data.secondarySubtitle2">{{ data.secondarySubtitle2 }}</h4>
      <h5 *ngIf="data.tertiarySubtitle2">{{ data.tertiarySubtitle2 }}</h5>

      <li *ngFor="let item of data.bulletList2" [ngClass]="{ numbered: item.type === 'numbered', bulleted: item.type === 'bullet' }">
        <b>{{ item.title }}</b> <span [innerHTML]="item.body"></span>
      </li>
    </ul>

    <ul *ngIf="data.bulletList3 && data.bulletList3.length > 0" class="modal-list">
      <h4 *ngIf="data.secondarySubtitle3">{{ data.secondarySubtitle3 }}</h4>
      <h5 *ngIf="data.tertiarySubtitle3">{{ data.tertiarySubtitle3 }}</h5>

      <li *ngFor="let item of data.bulletList3" [ngClass]="{ numbered: item.type === 'numbered', bulleted: item.type === 'bullet' }">
        <b>{{ item.title }}</b> <span [innerHTML]="item.body"></span>
      </li>
    </ul>
  </div>

  <div class="modal-footer">
    <button id="close-button" (click)="close()">Close</button>
  </div>
</div>
