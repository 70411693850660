import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";

@Component({
  selector: "app-time-picker",
  templateUrl: "./time-picker.component.html",
  styleUrls: ["./time-picker.component.scss"],
})
export class TimePickerComponent {
  @Input() timePickerForm: FormGroup;
  @Input() selectedTime: string | null = null;
  @Output() timeSelected = new EventEmitter<string>();

  hours: string[] = Array.from({ length: 12 }, (_, i) => (i + 1).toString());
  minutes: string[] = Array.from({ length: 60 }, (_, i) => (i < 10 ? "0" + i.toString() : i.toString()));

  selectedHours: string | null = null;
  selectedMinutes: string | null = null;
  selectedTimeOfDay: string | null = null;

  private parseTime(timeString: string | null): { hours: string; minutes: string; timeOfDay: string } | null {
    if (timeString && timeString.includes(":")) {
      const [time, timeOfDay] = timeString.split(" ");
      const [hours, minutes] = time.split(":");

      const formattedHours = hours.toString();

      //? Leading zeros break time picker for some reason
      const fixedHours = formattedHours.length && formattedHours[0] === "0" ? formattedHours.slice(1) : formattedHours;
      // console.log(`fixedHours: '${fixedHours}'`);

      const formattedMinutes = minutes.toString();

      return { hours: fixedHours, minutes: formattedMinutes, timeOfDay };
    }
    return null;
  }

  constructor(private formBuilder: FormBuilder, private cdRef: ChangeDetectorRef) {
    this.timePickerForm = this.formBuilder.group({
      selectedHours: [null],
      selectedMinutes: [null],
      selectedTimeOfDay: [null],
    });

    // Subscribe to changes in the form controls and update the properties
    this.timePickerForm.valueChanges.subscribe((values) => {
      this.selectedHours = values.hours;
      this.selectedMinutes = values.minutes;
      this.selectedTimeOfDay = values.timeOfDay;
    });
  }

  ngOnInit() {
    this.timePickerForm.valueChanges.subscribe((value) => {
      const { hours, minutes, timeOfDay } = value;
      const selectedTime = `${hours}:${minutes} ${timeOfDay}`;
      this.timeSelected.emit(selectedTime);

      // Trigger change detection to avoid ExpressionChangedAfterItHasBeenCheckedError
      this.cdRef.detectChanges();
    });
  }

  updateTime() {
    const formValue = this.timePickerForm.value;
    const { selectedHours, selectedMinutes, selectedTimeOfDay } = formValue;

    if (selectedHours && selectedMinutes && selectedTimeOfDay) {
      const selectedTime = `${selectedHours}:${selectedMinutes} ${selectedTimeOfDay}`;
      console.log(`Emitting: '${selectedTime}' to parent`);
      this.selectedTime = selectedTime;
      this.timeSelected.emit(selectedTime);
    }
  }

  updateDropdownValues(initialTimeStr: string | null = null) {
    if (initialTimeStr && this.selectedTime !== initialTimeStr) {
      console.dir({ initialTimeStr });
      const initialValue = this.parseTime(initialTimeStr);
      if (initialValue) {
        console.dir({ initialValue });
        const { hours, minutes, timeOfDay } = initialValue;
        this.timePickerForm.setValue({
          selectedHours: hours,
          selectedMinutes: minutes,
          selectedTimeOfDay: timeOfDay,
        });
      }
    } else if (this.selectedHours && this.selectedMinutes && this.selectedTimeOfDay) {
      console.dir({ value: this.timePickerForm?.value || "not set" });
      const { hours, minutes, timeOfDay } = this.timePickerForm.value;
      this.selectedTime = `${hours}:${minutes} ${timeOfDay}`;
    }
  }
}
