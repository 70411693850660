import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "./auth.service";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root"
})

export class TimeoutService {
  public preconstructedDateObject: { date: Date; formattedDate: string; } | null = null;
  public sessionStart: any;
  public sessionEnd: any;
  public timeout: number;
  public warningTime: number;
  private timer: any;
  private TIMEOUT_IN_MINUTES: number = 30;
  private SEND_WARNING_WHEN_X_MINUTES_LEFT_IN_SESSION: number = 2;
  private intervalId: number | null = null;

  constructor(
    public snackBar: MatSnackBar,
    private authService: AuthService
  ) {
    this.timeout = (environment.local ? 999999999999 : this.TIMEOUT_IN_MINUTES) * 60 * 1000;
    this.warningTime = this.SEND_WARNING_WHEN_X_MINUTES_LEFT_IN_SESSION * 60 * 1000;
    this.timer = null;
  }

  buildNowObject(
    sessionEndInMinutes: number | null = null,
    preconstructedDateObject: any
  ): object {
    const LEADING_DATE_STRING = "0";

    let
      date: Date = new Date(),
      minuteModifier = sessionEndInMinutes ? (date.getMinutes() + sessionEndInMinutes) : date.getMinutes(),
      minutes = (LEADING_DATE_STRING + minuteModifier).slice(-2);

    // Use Start Time If Exists
    if(preconstructedDateObject !== null) date = preconstructedDateObject.date;

    const
      month = (LEADING_DATE_STRING + (date.getMonth() + 1)).slice(-2),
      day = (LEADING_DATE_STRING + date.getDate()).slice(-2),
      year = (LEADING_DATE_STRING + date.getFullYear()).slice(-2),
      hours = (LEADING_DATE_STRING + date.getHours()).slice(-2),
      seconds = (LEADING_DATE_STRING + date.getSeconds()).slice(-2);

    return {
      date,
      formattedDate: `${month}/${day}/${year}-${hours}:${minutes}:${seconds}`
    };
  }

  beginSession(): void {
    // Track Session Start and End
    this.sessionStart = this.buildNowObject(null, null);
    this.sessionEnd = this.buildNowObject(5, this.sessionStart);
    console.log("Begin the User Session", this.sessionStart.formattedDate);
    console.log("End the User Session At ", this.sessionEnd.formattedDate);

    this.startClock();
  }

  startClock(): void {
    this.setTimer();
    this.startPrinting();
  }

  setTimer(): void {
    if(this.timer !== null) clearTimeout(this.timer);
    if (!environment.local) {
      this.timer = setTimeout(() => this.showWarning(), this.timeout - this.warningTime);
    }
  }

  showWarning(): void {
    this.timer = setTimeout(() => this.authService.initiateLogout(), this.warningTime);
    const warningMessage = `You will be logged out within the next ${this.SEND_WARNING_WHEN_X_MINUTES_LEFT_IN_SESSION} minutes due to session inactivity. Click OK to reset the timer.`;
    const snackBarRef = this.snackBar.open(warningMessage, 'OK', {
      duration: Infinity, // Duration in milliseconds
    });

    snackBarRef.afterDismissed().subscribe(() => {
      this.beginSession();
    });
  }

  startPrinting(): void {
    if(this.intervalId) {
      console.log("Interval ID already Set");
      if (this.timer !== null) {
        const elapsedTime = Date.now() - this.sessionStart.date.getTime();
        const remainingTime = Math.max(0, this.timeout - elapsedTime);
        console.log(`Remaining time before logout: ${remainingTime / 1000} seconds`);
      }
    } else {
      this.intervalId = setInterval(() => {
        // Check if this.timer has been initialized
        if (this.timer !== null) {
          const elapsedTime = Date.now() - this.sessionStart.date.getTime();
          const remainingTime = Math.max(0, this.timeout - elapsedTime);
          if (!environment.local) {
            console.log(`Remaining time before logout: ${(remainingTime / 1000).toFixed(0)} seconds`);
          }
        } else {
          // Init Timer
          this.startClock();
        }
      }, 5000);
      console.log("Created Interval ID: ", this.intervalId);
    }
  }

  ngOnDestroy(): void {
    if(this.intervalId) clearInterval(this.intervalId);
  }
}
