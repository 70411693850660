import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable, throwError, of, map } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CustomErrorHandler } from "./error-handler.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  apiUrl: string = environment.apiUrl;

  private handleHttpError(error: HttpErrorResponse): Observable<never> {
    if (!environment.production) {
      // Only log errors in non-production environments
      this.errorHandler.handleError(error);
    }
    // TODO: check this is prod/staging and make sure it doesn't break anything
    return throwError(() => error);
  }

  private generateOptions(
    protectedRequest: boolean = true,
    params: any = null
  ): { headers: HttpHeaders; withCredentials?: boolean; params?: HttpParams } {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    const options: { headers: HttpHeaders; withCredentials?: boolean; params?: HttpParams } = {
      headers,
      withCredentials: !!protectedRequest,
    };

    if (params) {
      options.params = new HttpParams();

      Object.keys(params).forEach((key) => {
        options.params = options.params?.set(key, params[key]);
      });
    }

    return options;
  }

  private generateImageOptions(
    protectedRequest: boolean = true,
    params: any = null
  ): { headers: HttpHeaders; withCredentials?: boolean; params?: HttpParams } {
    console.log("Inside Generate Options Call");

    const headers = new HttpHeaders({
      "Content-Type": "multipart/form-data",
    });

    const options: { headers: HttpHeaders; withCredentials?: boolean; params?: HttpParams } = {
      headers,
      withCredentials: !!protectedRequest,
    };

    if (params) {
      options.params = new HttpParams();

      Object.keys(params).forEach((key) => {
        options.params = options.params?.set(key, params[key]);
      });
    }

    return options;
  }

  constructor(private http: HttpClient, private errorHandler: CustomErrorHandler) {}

  getCall<T>(url: string, protectedRequest: boolean = true): Observable<T> {
    const options = this.generateOptions(protectedRequest);

    // Log the complete URL, method, and options
    if (environment.debugMode) {
      console.log(`Request URL: ${this.apiUrl + url}`);
      console.log(`Request Method: GET`);
      console.log(`Request Options:`, options);
    }

    return this.http.get<T>(this.apiUrl + url, options).pipe(catchError((error: HttpErrorResponse) => this.handleHttpError(error)));
  }

  postCall<T>(url: string, body: any, protectedRequest: boolean = true): Observable<T> {
    const options = this.generateOptions(protectedRequest);
    return this.http.post<T>(this.apiUrl + url, body, options).pipe(catchError((error: HttpErrorResponse) => this.handleHttpError(error)));
  }

  putCall<T>(url: string, body: any, protectedRequest: boolean = true): Observable<T> {
    const options = this.generateOptions(protectedRequest);
    return this.http.put<T>(this.apiUrl + url, body, options).pipe(catchError((error: HttpErrorResponse) => this.handleHttpError(error)));
  }

  putCallForImageUploads<T>(url: string, body: any, protectedRequest: boolean = true): Observable<T> {
    const options = this.generateImageOptions(protectedRequest);
    console.log("Options built were: ", options);
    return this.http.put<T>(this.apiUrl + url, body, options).pipe(catchError((error: HttpErrorResponse) => this.handleHttpError(error)));
  }

  deleteCall<T>(url: string, protectedRequest: boolean = true, params: any = null): Observable<T> {
    const options = this.generateOptions(protectedRequest, params);

    // Log the complete URL, method, and options
    if (environment.debugMode) {
      console.log(`Request URL: ${this.apiUrl + url}`);
      console.log(`Request Method: DELETE`);
      console.log(`Request Options:`, options);
    }

    return this.http.delete<T>(this.apiUrl + url, options).pipe(catchError((error: HttpErrorResponse) => this.handleHttpError(error)));
  }

  deleteCallWithBody<T>(url: string, protectedRequest: boolean = true, body: any = null, params: any = null): Observable<T> {
    const options = {
      ...this.generateOptions(protectedRequest, params),
      body: body
    };

    // Log the complete URL, method, and options
    if (environment.debugMode) {
      console.log(`Request URL: ${this.apiUrl + url}`);
      console.log(`Request Method: DELETE`);
      console.log(`Request Options:`, options);
    }

    return this.http.delete<T>(this.apiUrl + url, options)
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleHttpError(error))
      );
  }
}
