import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule, HammerModule } from "@angular/platform-browser";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { MatNativeDateModule } from "@angular/material/core";
//! NOTE: If you need to add or import any (non-core) Angular Material modules (or other shared, reusable components), please do so in the SharedModule.
//! This helps keep the application organized and prevents redundancy.


import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { environment } from "src/environments/environment";
import { SharedModule } from "./shared/shared.module";
import { PagesComponent } from "./pages/pages.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { CustomErrorHandler } from "./services/error-handler.service";
import { TimeoutService } from "./services/timeout.service";

export function HttpLoaderFactory(httpClient: HttpClient) {
	// console.dir({ environment });
	return new TranslateHttpLoader(httpClient, environment.url + "/assets/i18n/", ".json");
}
// import { LangComponent } from "./theme/components/lang/lang.component";
import { Settings, AppSettings } from "./app.settings";
import { AuthService } from "./services/auth.service";
import { DropdownDirective } from "./shared/directives/dropdown.directive";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { CurrencyPipe } from "@angular/common";

@NgModule({
	declarations: [AppComponent, PagesComponent, NotFoundComponent, ConfirmationDialogComponent],
	imports: [
		AppRoutingModule,
		BrowserModule,
		FormsModule,
		HttpClientModule,
		MatNativeDateModule,
		ReactiveFormsModule,
		SharedModule,
		HammerModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		})
	],
	providers: [AppSettings, { provide: ErrorHandler, useClass: CustomErrorHandler }, TimeoutService, AuthService, CurrencyPipe],
	bootstrap: [AppComponent],
	exports: []
})
export class AppModule {
}
