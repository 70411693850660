import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { AlertDialogComponent } from "./alert-dialog/alert-dialog.component";
import { DirectivesModule } from "../theme/directives/directives.module";
import { NavigationComponent } from "./navigation/navigation.component";
import { AteaseLogoDarkCyanComponent } from "./svg-images/atease-logo-dark-cyan/atease-logo-dark-cyan.component";
import { GenericModalComponent } from "./generic-modal/generic-modal.component";
import { ComplexModalComponent } from "./complex-modal/complex-modal.component";
import { PaginationComponent } from "./pagination/pagination.component";
import { TimePickerComponent } from "./time-picker/time-picker.component";
import { OnlyNumbersDirective } from "./directives/onlyNumbers.directive";

import { IgxTimePickerModule } from "igniteui-angular";

@NgModule({
  declarations: [
    AlertDialogComponent,
    NavigationComponent,
    PaginationComponent,
    ComplexModalComponent,
    GenericModalComponent,
    PaginationComponent,
    TimePickerComponent,
    OnlyNumbersDirective,
  ],
  imports: [
    AteaseLogoDarkCyanComponent,
    RouterModule,
    CommonModule,
    IgxTimePickerModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatGridListModule,
    MatCardModule,
    ReactiveFormsModule,
    TranslateModule,
    DirectivesModule,
  ],
  exports: [
    AlertDialogComponent,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    TranslateModule,
    NavigationComponent,
    DirectivesModule,
    PaginationComponent,
    TimePickerComponent,
  ],
  providers: [],
  bootstrap: [],
})
export class SharedModule {}
