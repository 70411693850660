import { Directive, ElementRef, Output, EventEmitter, AfterViewInit } from '@angular/core';
declare var google: any;

@Directive({
  selector: '[appGooglePlace]'
})
export class GooglePlaceDirective implements AfterViewInit {
  @Output() setAddress: EventEmitter<any> = new EventEmitter();

  private autocomplete: any;

  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    this.autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, {
      componentRestrictions: { country: ['us'] },
      types: ['address']
    });

    this.autocomplete.addListener('place_changed', () => {
      let place = this.autocomplete.getPlace();
      this.setAddress.emit(place);
    });
  }
}
