import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})

export class PaginationComponent {
  @Output() dataEvent = new EventEmitter<any>();
  @Input() parentComponentRoute!: string;
  @Input() parentDataList!: {
    totalCount: number;
    data: any[];
    currentPage: number;
    rowsPerPage: number;
    pages: number;
  };
  /**
   * Component Vars
   * */
  public paginationStats: {
    page: number;
    pages: number;
    rowsPerPage: number;
    totalCount: number;
  } = { page: 1, pages: 1, rowsPerPage: 2, totalCount: 0 };

  /**
   * Constructor
   * */
  constructor() {}

  /**
   * LifeCycle Methods
   * */
  ngOnChanges(changes: any) {
    console.log("Change detected, ", changes);
    if (changes.parentComponentRoute && changes.parentComponentRoute.currentValue) {
      // Handle the new value here
      console.log("changes?", changes.parentComponentRoute);
    }
    if (changes.parentDataList && changes.parentDataList.currentValue) {
      // Handle the new value here
      console.log("changes?", changes.parentDataList);
      this.paginationStats.page = changes.parentDataList.currentPage;
      this.paginationStats.rowsPerPage = changes.parentDataList.rowsPerPage || this.paginationStats.rowsPerPage;
    }
  }

  /**
   * Navigation And Call Helpers
   * */
  navigateToPreviousPage(route: string) {
    if(this.parentDataList.currentPage > 1) {
      let previousPage = this.parentDataList.currentPage - 1;
      let navigationOptions = { route, pageNumber: previousPage, rowsPerPage: this.parentDataList.rowsPerPage || this.paginationStats.rowsPerPage };
      this.dataEvent.emit(navigationOptions);
    }
  }

  navigateToSpecificPage(pageNumber: number, route: string) {
    let navigationOptions = { route, pageNumber, rowsPerPage: this.parentDataList.rowsPerPage || this.paginationStats.rowsPerPage };
    this.dataEvent.emit(navigationOptions);
  }

  navigateToNextPage(route: string) {
    if(this.parentDataList.currentPage <= this.parentDataList.pages) {
      let nextPage = this.parentDataList.currentPage + 1;
      let navigationOptions = { route, pageNumber: nextPage, rowsPerPage: this.parentDataList.rowsPerPage || this.paginationStats.rowsPerPage };
      this.dataEvent.emit(navigationOptions);
    }
  }

  /**
   * Pagination Utils
   * */
  buildPageNumbers(lastPage: number): Array<number> { return Array(lastPage).fill(1).map((x, i) => i) };

  isDisabled(link: string): boolean {
    if(link === "previous" && this.parentDataList.currentPage <= 1) return true;
    if(link === "next" && this.parentDataList.currentPage >= this.parentDataList.pages) return true;
    return false;
  }

  showIncreasingResultsPerPage(): number {
    let
      onLastPage = this.parentDataList.currentPage === this.parentDataList.pages,
      resultCount = this.parentDataList.currentPage * this.parentDataList.data.length; // 1, 2, 3, 10, 10, 8
    if(onLastPage) {
      resultCount = (
        (this.parentDataList.currentPage - 1) *
        (this.parentDataList.rowsPerPage || this.paginationStats.rowsPerPage)) +
        this.parentDataList.data.length;
    }
    return resultCount;
  }
}
