<div class="time-picker" [formGroup]="timePickerForm">
  <!-- Hours Dropdown -->
  <mat-select class="num-select" (selectionChange)="updateTime()" placeholder="HH" formControlName="selectedHours">
    <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }}</mat-option>
  </mat-select>

  <span class="time-divider">:</span>

  <!-- Minutes Dropdown -->
  <mat-select class="num-select" (selectionChange)="updateTime()" placeholder="mm" formControlName="selectedMinutes">
    <mat-option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</mat-option>
  </mat-select>

  <!-- AM/PM Dropdown -->
  <mat-select placeholder="AM/PM" (selectionChange)="updateTime()" formControlName="selectedTimeOfDay">
    <mat-option value="AM">AM</mat-option>
    <mat-option value="PM">PM</mat-option>
  </mat-select>
</div>
