import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OnlyNumberDirective } from "./only-number.directive";
import { MaskedPhoneNumberDirective } from "./masked-phone-number.directive";
import { GooglePlaceDirective } from "./googleAddressTypeahead.directive";

@NgModule({
  declarations: [OnlyNumberDirective, MaskedPhoneNumberDirective, GooglePlaceDirective],
  exports: [OnlyNumberDirective, MaskedPhoneNumberDirective, GooglePlaceDirective],
  imports: [CommonModule],
})
export class DirectivesModule {}
