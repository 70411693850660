import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";

export interface ModalData {
  titleColor?: string;
  title: string;
  subtitle?: string;
  body?: string;
  listStyleType: "numbered" | "bullet" | "none";
  bulletList?: Array<{ title: string; body: string }>;
  bottomText?: string;
}

@Component({
  selector: "app-generic-modal",
  templateUrl: "./generic-modal.component.html",
  styleUrls: ["./generic-modal.component.scss"],
})
export class GenericModalComponent {
  constructor(public dialogRef: MatDialogRef<GenericModalComponent>, @Inject(MAT_DIALOG_DATA) public data: ModalData) {}

  close(): void {
    this.dialogRef.close();
  }
}
