import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { NavigationService } from "./services/navigation.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "atease-host";

  constructor(
    public router: Router,
    public translate: TranslateService,
    private navigationService: NavigationService,
  ) {
    translate.addLangs(["en", "de", "fr", "ru", "tr"]);
    translate.setDefaultLang("en");
    translate.use("en");
  }

  ngOnInit() {
    //this.navigationService.onSiteRefresh();
  }
}
