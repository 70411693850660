<div class="generic__pagination">
  <div><p>{{paginationStats.page}}</p></div>
  <div>
    <a
      *ngIf="!isDisabled('previous'); else previousButtonElseBlock"
      class="generic__pagination-navigation"
      style="cursor: pointer;"
      (click)="navigateToPreviousPage(parentComponentRoute)"
    >
      <span class="is-prev"></span>Prev
    </a>
    <div class="generic__pagination-btn">
      <a
        *ngFor="let num of buildPageNumbers(parentDataList.pages ?? 1)"
        style="cursor: pointer;"
        class="{{parentDataList.currentPage === (num + 1) ? 'is-active' : 'not-active'}}"
        (click)="navigateToSpecificPage((num + 1), parentComponentRoute)">
        {{num + 1}}
      </a>
    </div>
    <ng-template #previousButtonElseBlock
                 class="generic__pagination-navigation is-disabled"
    >
      <a class="generic__pagination-navigation is-disabled" style="cursor: pointer;">
        <span class="is-prev"></span>Prev
      </a>
    </ng-template>
    <a
      *ngIf="!isDisabled('next'); else nextButtonElseBlock"
      style="cursor: pointer;"
      class="generic__pagination-navigation"
      (click)="navigateToNextPage(parentComponentRoute)"
    >
      Next<span class="is-next"></span>
    </a>
    <ng-template #nextButtonElseBlock
                 class="generic__pagination-navigation is-disabled"
    >
      <a class="generic__pagination-navigation is-disabled" style="cursor: pointer;">
        Next<span class="is-next"></span>
      </a>
    </ng-template>
  </div>
  <div>
    <div class="generic__pagination-info">
      <p>
        <strong>
          {{showIncreasingResultsPerPage()}}
        </strong> of
        <strong>
          {{parentDataList.totalCount}}
        </strong> results
      </p>
      <p>
        <strong>
          {{parentDataList.rowsPerPage ? parentDataList.rowsPerPage : paginationStats.rowsPerPage}}
        </strong> Items Max Per Page
      </p>
    </div>
  </div>
</div>
