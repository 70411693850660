import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { StorageService } from "../../services/storage.service";
import { AuthService } from "../../services/auth.service";
import { NavigationService } from "../../services/navigation.service";
import { initializeNavigationModelData, NavigationModel } from "../models/navigation.model";

@Component({
  selector: "navigation-component",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})

export class NavigationComponent implements OnInit, OnDestroy {
  public data: NavigationModel;
  navSideBarOpen: boolean = true;

  constructor(
    private storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private navigationService: NavigationService
  ) {
    this.data = this.navigationService.navigationDetailModel;
  }

  ngOnInit() {
    console.log("try to fix refresh issue?");
    // Determine User Role And Show Appropriate Info On Dashboard
    const data: any = this.navigationService.getUserData();
    console.log("data for user upon refresh? ", data);
  }

  ngOnDestroy() {}

  onNavigate(path: string) {
    this.navigationService.navigateTo(path, this.router.url);
  }

  logout(): void {
    this.authService.initiateLogout();
  }

  onToggleSideNavBar() {
    this.navSideBarOpen = !this.navSideBarOpen;
  }
}
