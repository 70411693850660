import { Injectable, ErrorHandler } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({
  // This ensures that the service is a singleton and available app-wide
  providedIn: "root",
})
export class CustomErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    if (environment.production && !environment.debugMode) {
      // Check if the error is an HTTP error
      if (error instanceof HttpErrorResponse) {
        // Don't log HTTP errors in production
        return;
      }
      // Handle other errors here as needed
      console.error("API Error:", error);
    } else {
      // Log all errors in development
      console.error("API Error:", error);
    }
  }
}
