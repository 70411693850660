import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";

export interface ComplexModalData {
  titleColor?: string;
  title: string;
  subtitle?: string;
  body?: string;

  secondarySubtitle1?: string;
  tertiarySubtitle1?: string;
  bulletList1?: Array<{ type: "numbered" | "bullet"; title: string; body: string }>;

  secondarySubtitle2?: string;
  tertiarySubtitle2?: string;
  bulletList2?: Array<{ type: "numbered" | "bullet"; title: string; body: string }>;

  secondarySubtitle3?: string;
  tertiarySubtitle3?: string;
  bulletList3?: Array<{ type: "numbered" | "bullet"; title: string; body: string }>;
}

@Component({
  selector: "app-complex-modal",
  templateUrl: "./complex-modal.component.html",
  styleUrls: ["./complex-modal.component.scss"],
})
export class ComplexModalComponent {
  constructor(public dialogRef: MatDialogRef<ComplexModalComponent>, @Inject(MAT_DIALOG_DATA) public data: ComplexModalData) {}

  close(): void {
    this.dialogRef.close();
  }
}
