<div class="app-generic-modal">
  <h2 [style.color]="data.titleColor || 'black'" [style.text-shadow]="data.titleColor">{{ data.title }}</h2>

  <div class="modal-content">
    <h3 [innerHTML]="data.subtitle"></h3>
    <ul>
      <li [ngClass]="'modal-style-' + data.listStyleType" *ngFor="let item of data.bulletList">
        <b>{{ item.title }}</b
        ><span [innerHTML]="item.body"></span>
      </li>
    </ul>

    <ng-container *ngIf="data.bottomText">
      <p class="modal-bottom-text">{{ data.bottomText }}</p>
    </ng-container>
  </div>

  <div class="modal-footer">
    <button id="close-button" (click)="close()">Close</button>
  </div>
</div>
