export interface AuthModel {
  user: any;
  isLoggedIn: boolean;
  role: string | undefined;
}

export const initializeAuthModelData = {
  user: null,
  isLoggedIn: false,
  role: undefined
}
