import { Component, Input } from "@angular/core";

@Component({
  standalone: true,
  selector: "app-atease-logo-dark-cyan",
  templateUrl: "./atease-logo-dark-cyan.component.svg",
  styleUrls: ["./atease-logo-dark-cyan.component.scss"],
})
export class AteaseLogoDarkCyanComponent {
  @Input() width: string = "200";
  @Input() viewBox: string = "0 0 101 35";

  setDimensions(newWidth: string, newViewBox: string): void {
    this.width = newWidth;
    this.viewBox = newViewBox;
  }
}
