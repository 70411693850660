import { Injectable, OnInit } from "@angular/core";
import { ApiService } from "./api.service";
import { RegistrantModel } from "../shared/models/user.model";
import { catchError, map } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { StorageService } from "./storage.service";
import { AuthModel, initializeAuthModelData } from "../shared/models/auth.model";

@Injectable({
  providedIn: "root"
})

export class AuthService implements OnInit {
  public authModel: AuthModel;

  constructor(
    private apiService: ApiService,
    public snackBar: MatSnackBar,
    private router: Router,
    private storage: StorageService,
  ) {
    this.authModel = initializeAuthModelData;
  }

  ngOnInit() {
    console.log("Init from Auth Service TS")
    const dataFromStorage: any = this.storage.getRegistrantData();
    console.log("User Data Exists? ", dataFromStorage);
    if(dataFromStorage) {
      this.setLoggedInUserData(dataFromStorage);
    }
  }

  setLoggedInUserData(user: any): void {
    console.log("Try to set authModel with data ", user);

    this.authModel.user = user;
    this.authModel.isLoggedIn = !!user;
    this.authModel.role = user.role;
    this.storage.addSession(this.authModel);
  }

  getAuthModelData(): AuthModel {
    return this.authModel;
  }

  /**
   * Auth Actions
   * */
  // login
  // register
  // logout - try to make initiateLogout one call. -dkg.
  async initiateLogin(): Promise<any> {

  }
  initiateLogout(): void {
    this.apiService
      .postCall<any>("/auth/logout", {}, true)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(error);

          const snackbarMessage = error?.error?.statusText || "Unknown error";
          this.snackBar.open(snackbarMessage, "×", {
            panelClass: "error",
            horizontalPosition: "right",
            verticalPosition: "top",
            duration: 3000,
          });
          this.storage.clearAll();
          window.location.href = "/";
          return [];
        })
      )
      .subscribe({
        next: (resp: any) => {
          console.log("Logging out, From auth.service: ", JSON.stringify(resp));
          this.storage.clearAll();
          window.location.href = "/";
        },
        error: (error: HttpErrorResponse) => {
          const snackBarMessage = error?.error?.statusText || "Invalid Login Response";
          this.snackBar.open(snackBarMessage, "×", {
            panelClass: "error",
            horizontalPosition: "right",
            verticalPosition: "top",
            duration: 3000,
          });
          this.storage.clearAll();
          window.location.href = "/";
        },
      });
  }

  // verifyLoggedIn
  /* verifyLoggedInUser(): void {
     this.apiService
       .getCall<RegistrantModel>("/auth/isLoggedIn", true)
       .pipe(
         catchError((error: HttpErrorResponse) => {
           console.error(error);

           const snackbarMessage = error?.error?.statusText || "Unknown error";
           this.snackBar.open(snackbarMessage, "×", {
             panelClass: "error",
             horizontalPosition: "right",
             verticalPosition: "top",
             duration: 3000,
           });

           return [];
         })
       )
       .subscribe({
         next: (resp: any) => {
           console.log("Getting Logged In Status: ", JSON.stringify(resp));
           this.isLoggedIn = resp.isLoggedIn;
         },
         error: (error: HttpErrorResponse) => {
           const snackBarMessage = error?.error?.statusText || "Invalid Login Response";
           this.snackBar.open(snackBarMessage, "×", {
             panelClass: "error",
             horizontalPosition: "right",
             verticalPosition: "top",
             duration: 3000,
           });
         },
       });
   };*/

  // forgotPassword
  initiateForgotPassword(username: string) {
    return this.apiService
      .putCall<any>("/auth/resetPassword", { username }, false)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(error);

          const snackbarMessage = error?.error?.statusText || "Unknown error";
          this.snackBar.open(snackbarMessage, "×", {
            panelClass: "error",
            horizontalPosition: "right",
            verticalPosition: "top",
            duration: 3000,
          });
          this.storage.clearAll();
          window.location.href = "/";
          return [];
        }),
        map((response: any) => {
          console.log("Response for resetPassword: ", response);
          return response;
        })
      )
  }

  // changePassword
  changePassword(resetToken: string, newPassword: string) {
    return this.apiService
      .postCall<any>(`/auth/changePassword?resetToken=${resetToken}`, { password: newPassword }, false)
      .pipe(
        catchError((error: HttpErrorResponse): any => {
          const snackbarMessage = error?.error?.data || "Unknown error";
          let dialogRef = this.snackBar.open(snackbarMessage, "×", {
            panelClass: "error",
            horizontalPosition: "right",
            verticalPosition: "top",
            duration: 6000,
          });
          return dialogRef
            .afterDismissed()
            .subscribe(() => this.router.navigate(["/forgot"]));
        }),
        map((response: any) => {
          console.log("Response for resetPassword: ", response)
          return response;
        })
      )
  }

  // verifyResetToken
  verifyResetToken(resetToken: string) {
    return this.apiService
      .getCall<any>(`/auth/verifyResetTokenData?resetToken=${resetToken}`,  false)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(error);

          const snackbarMessage = error?.error?.statusText || "Unknown error";
          this.snackBar.open(snackbarMessage, "×", {
            panelClass: "error",
            horizontalPosition: "right",
            verticalPosition: "top",
            duration: 3000,
          });
          this.storage.clearAll();
          window.location.href = "/";
          return [];
        }),
        map(response => {
          console.log("Response for resetPassword: ", response)
          return response;
        })
      )
  }
}
