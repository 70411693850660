import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appOnlyNumbers]",
})
export class OnlyNumbersDirective {
  constructor(private el: ElementRef) {}

  @HostListener("input", ["$event"]) onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const initialValue = inputElement.value;

    // Remove non-numeric characters, except for a single leading zero and periods
    const sanitizedValue = initialValue.replace(/[^0-9.]/g, (_, index) => {
      if (index === 0 && (initialValue.charAt(0) === "0" || initialValue.charAt(0) === ".")) {
        return initialValue.charAt(0); // Allow a single leading zero or period
      } else if (initialValue.indexOf(".") !== -1 && _ === ".") {
        return ""; // Allow only one period in the input
      } else {
        return "";
      }
    });

    if (sanitizedValue !== initialValue) {
      inputElement.value = sanitizedValue;
      event.stopPropagation();
    }
  }
}
