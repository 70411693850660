import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "./auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { initializeNavigationModelData, NavigationModel } from "../shared/models/navigation.model";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root"
})

export class NavigationService {
  // Move to Model
  public navigationDetailModel: NavigationModel;

  constructor(
    private storage: StorageService,
    public snackBar: MatSnackBar,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { // Couple Values To Model
    this.navigationDetailModel = initializeNavigationModelData;
  }

  ngOnDestroy(): void {}

  /**
   * Service Functions
   * */
  public navigateTo(path: string, pushingFrom: string) {
    this.setUserData();
    console.error("Have Activated Route?? ", pushingFrom);
/*    if(pushingFrom === "AppComponent") {
      path = "/dashboard";
      pushingFrom = "site-refresh";
    }*/
    console.log("Before verify ", path, pushingFrom);
    const accessGranted = this.verifyPageAccess(path, pushingFrom);
    console.log("Access Granted? ", accessGranted);
    console.log("After restriction test? ", this.navigationDetailModel);
    console.log("Restricted? ", this.navigationDetailModel.pageRestrictedToUser);
    if(!this.navigationDetailModel.pageRestrictedToUser) {
      console.log("About to navigate?");//
      this.router.navigate([path]);
    }
  }

  setActivePageComponent(requestedPath: string, pushingFrom: string): NavigationModel {
    this.navigationDetailModel.pushingTo = requestedPath || "HomeComponent";
    this.navigationDetailModel.pushedFrom = pushingFrom;

    // Set Values
    this.navigationDetailModel.sitePages.dashboardIsActive = requestedPath === "/dashboard";
    this.navigationDetailModel.sitePages.calendarIsActive = requestedPath === "/calendars";
    this.navigationDetailModel.sitePages.propertyIsActive = requestedPath === "/properties";
    this.navigationDetailModel.sitePages.inspectionsIsActive = requestedPath === "/inspection";
    this.navigationDetailModel.sitePages.paymentsIsActive = requestedPath === "/payout";
    this.navigationDetailModel.sitePages.bookingsAreActive = requestedPath === "/bookings";
    this.navigationDetailModel.sitePages.profileIsActive = requestedPath === "/profile";
    this.navigationDetailModel.sitePages.userPageIsActive = requestedPath === "/admin/users";

    return this.navigationDetailModel;
  }

  verifyPageAccess(path: string, pushingFrom: string): boolean { // Flag For User Access To Specific Page
    let userSnapshot: any = this.getUserData();

    console.log(path, pushingFrom, userSnapshot);

    if(userSnapshot.role) {
      const
        hostIsRequesting = userSnapshot.role === 'host',
        adminIsRequesting = userSnapshot.role === 'admin' || userSnapshot.role === 'support',
        sharedPages = ["", "/dashboard", "/calendars", "/properties", "/bookings", "/inspection", "/payout", "/profile"],
        hostApprovedPages = [...sharedPages],
        adminApprovedPages = [...sharedPages, "/admin/users", "/admin/payouts"];

      if(hostIsRequesting) {
        this.navigationDetailModel.pageRestrictedToUser = hostApprovedPages.filter(entryInList => entryInList === path).length === 0;
        console.log("Host Requesting: ", this.navigationDetailModel, this.navigationDetailModel.pageRestrictedToUser);
        this.setActivePageComponent(path, pushingFrom);
      } else if (adminIsRequesting) {
        this.navigationDetailModel.pageRestrictedToUser = adminApprovedPages.filter(entryInList => entryInList === path).length === 0;
        this.setActivePageComponent(path, pushingFrom);
      }
    }

    return this.navigationDetailModel.pageRestrictedToUser;
  }

  setUserData() {
    let data: any = this.authService.getAuthModelData();
    if(!data?.user) {
      // Try to source session
      let sessionData: any | null = this.storage.getSession();
      if(sessionData !== null) data = JSON.parse(sessionData);
    }

    if(data?.user) {
      this.navigationDetailModel.loggedInName = `${data.user.firstName} ${data.user.lastName}`;
      this.navigationDetailModel.role = data.role;
    }
  }

  getUserData(): NavigationModel {
    this.setUserData();
    return this.navigationDetailModel;
  }

  onSiteRefresh() {
    console.log("Refresh Detected, Reset Data Push To Dashboard");
    this.navigateTo("/dashboard", "site-refresh");
  }
}
